<template>
  <div class="main">
    <div class="text-box">
      <div class="head">一、成长值介绍</div>
      <div class="content">
        平台会员等级自会员资质认证成功当天开始计算，以会员获取的成长值总和为依据，当成长值到达等级要求时将自动成为对应等级的平台会员，每年1月1日、4月1日、7月1日、10月1日更新会员等级，共分为7个等级及成长值要求。成长值=x</div>
      </div>

    <div  class="content">
        <table>
          <tbody>
            <tr class="">
              <!-- ● -->
              <td>V1.普通会员：0≤x≤3000</td>
              <td>V2.标准会员：3000＜x≤5000</td>
              <td>V3.标准会员：5000＜x≤10000</td>
            </tr>
            <tr class="">
              <td>V4.五星会员：10000＜x≤20000</td>
              <td>V5.金牌会员：20000＜x≤50000</td>
              <td>V6.钻石会员：50000＜x≤100000</td>
            </tr>
            <tr class="">
              <td>V7.超级会员：100000＜x≤250000</td>
              <td>V8.至尊会员：x＞250000</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      ●●
    <div class="text-box">
      <div class="head">二、如何获取成长值</div>
      <div class="content">①. 用户在3A平台上完成订单交易可积累成长值，系数为1；</div>
      <div class="content">②. 成长值=订单金额*1（向下取整）；</div>
      <div class="content">③. 订单发货成功时成长值算入累计获取。</div>
    </div>
    <div class="text-box">
      <div class="head">三、充值获取等级权益</div>
      <div class="content">
        平台会员可通过一次性充值，免费获取平台等级权益，一次性充值金额达到等级要求时自动成为对应等级的平台会员，充值金额不可累积，共分为7个等级及对应的一次性充值金额要求（单位：元）
      </div>
      <div class="content">
        * 例：当前自身等级V1，充值5000后，升级至V2；如当前自身等级V3，充值5000后，等级不变。
      </div>
      <div  class="content">
        <table>
          <tbody>
            <tr class="">
              <td>V1.普通会员：无</td>
              <td>V2.标准会员：5000</td>
              <td>V3.VIP会员：10000</td>
            </tr>
            <tr class="">
              <td>V4.五星会员：20000</td>
              <td>V5.金牌会员：50000</td>
              <td>V6.钻石会员：100000</td>
            </tr>
            <tr class="">
              <td>V7.超级会员：200000</td>
              <td>V8.至尊会员：500000</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="text-box">
      <div class="head">四、等级有效期是多久</div>
      <div class="content sub-title">
        ①. 每年1月1日、4月1日、7月1日和10月1日更新成长值
      </div>
      <div class="content sub-title">
        ②. 更新后会员等级由用户上一次更新后 至今累计获取的成长值总和决定，更新后会员等级有效期至下一次更新的前一天。
      </div>
      <div class="content sub-title">
        ③. 每个周期中，用户累计获取的成长值达到更高等级门槛时会实时升级。
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "IntegralRule",
    components: {
    },
    data() {
      return {
        tableData: [{
          date: 'V1等级：0≤x≤3000 ',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: 'V4等级：1',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          date: 'V4等级：1',
          name: '',
          address: ''
        }]
      };
    },
    created() {},
    methods: {},
    //   注册方法
    mounted() {}
  };

</script>

<style lang="scss" scoped>
table {
  margin: 10px 0;
    width: 100%;
    border-spacing:1px;
    display: table;
    tr{
      border: 1px solid #ccc;
      td {
        border: 1px solid #ccc;
        padding:5px 10px;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }


::-webkit-scrollbar {
    width: 2px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }
  .main {
    max-height:400px;
    overflow-y: scroll;
    padding:0px 20px;
    box-sizing: border-box;

    .title {
      font-size:15px;
      height:24px;
      line-height:21px;
      font-weight: 500;
      color: #333;
    }

    .text-box {
      margin-top:12px;
      text-align: left;
      overflow: auto;
      height: auto;

      .head {
        margin-bottom: 10px;
        font-size:14px;
        height:20px;
        line-height:20px;
        font-weight: 600;
        color: #333;
      }

      .content {
        font-size:13px;
        line-height: 18px;
        color: #666666;
        text-indent:20px;
      }

      .msg {
        margin:6px 0;
      }

      .sub-title {
        margin:4px 0;
      }
    }

  }

</style>
